import ignite from "./img/ignite.png";
import chillhopSwirl from "./img/chillhop-swirl.png";
import capture from "./img/capture.png";
import bobAndBarry from "./img/bob-and-barry.png";
import smoothieLuv from "./img/smoothie-luv.png";
import packingBuddy from "./img/packing-buddy.jpg";
import cybersecurity from "./img/cybersecurity.png";

export const projectsState = () => {
    return [
        {
            id: "capture",
            title: "Capture",
            description: "A mockup artist portfolio with lively animations built with React and Framer Motion.",
            tags: ["javascript", "react", "react-router-dom", "styled-compnents", "framer-motion"],
            img: capture,
            url: "https://capture-artist-portfolio.netlify.app"
        },
        {
            id: "chillhop-swirl",
            title: "ChillHop Swirl",
            description: "Study or relax to the best of chill hop with this retro React vinyl  music player.",
            tags: ["javascript", "react"],
            img: chillhopSwirl,
            url: "https://chillhop-swirl.netlify.app"
        },
        {
            id: "cybersecurity-final-project",
            title: "CS50's Introduction to Cybersecurity",
            description: "Final project for CS50's Introduction to Cybersecurity. I'm investigating a security vulnerability in the telematics system of SiriusXM Connected Vehicle Services as discovered by security researcher Sam Curry.",
            tags: ["cybersecurity", "CS50", "Final Cut Pro"],
            img: cybersecurity,
            url: "https://www.youtube.com/watch?v=UH_EmZuebck"
        },
        {
            id: "ignite",
            title: "Ignite",
            description: "Discover and search for the popular and upcoming games in this video game database created with the RAWG.io API and React.",
            tags: ["javascript", "react", "react-redux", "react-router-dom", "axios", "styled-compnents", "framer-motion"],
            img: ignite,
            url: "https://ignite-video-games.netlify.app"
        },
        {
            id: "bob-and-barry-catfights",
            title: "Bob & Barry - Catfights",
            description: "Battle your opponent in an epic cat fight in a vanilla javascript 2D arena.",
            tags: ["javascript", "jquery"],
            img: bobAndBarry,
            url: "https://bob-and-barry-catfights.netlify.app/",
            altUrl: "https://youtu.be/zkAvV_1FuSI"
        },
        {
            id: "smoothie-luv",
            title: "Smoothie Luv",
            description: "Discover and create delicious smoothie recipes in a simple and stylish Vue application.",
            tags: ["javascript", "vue", "materialize", "firebase"],
            img: smoothieLuv,
            url: "https://ninja-smoothies-57eae.firebaseapp.com/"
        },
        {
            id: "packing-buddy",
            title: "Packing Buddy",
            description: "A video presentation of my final CS50 project. Packing Buddy is an iOS travel organizer that structures and categorizes your travel essentials.",
            tags: ["ios", "xcode", "swift", "CS50", "Final Cut Pro"],
            img: packingBuddy,
            url: "https://www.youtube.com/watch?v=nEcnaGcOPi0"
        }
    ]
}