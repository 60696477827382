import React from "react";
import styled from "styled-components/macro";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";


const Nav = () => {
  const { pathname } = useLocation();

  return (
    <StyledNav>
      <ul>
        <li>
          <Link to="/">About</Link>
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/" ? "100%" : "0%" }}
          />
        </li>
        <li>
          <Link to="/projects">Projects</Link>
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname.includes("/projects") ? "100%" : "0%" }}
          />
        </li>
        <li>
          <Link to="/contact">Contact</Link>
          <Line
            transition={{ duration: 0.75 }}
            initial={{ width: "0%" }}
            animate={{ width: pathname === "/contact" ? "100%" : "0%" }}
          />
        </li>
      </ul>
    </StyledNav>
  );
}

const StyledNav = styled.nav`
  min-height: 10vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 2rem;
  margin: auto;
  top: 0;
  z-index: 10;
  
  a {
    font-family: 'Playfair Display', serif;
    color: #63A69F;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.5rem;
  }

  ul {
    display: flex;
    list-style: none;
  }

  li {
    padding-left: 5rem;
    position: relative;
  }


  @media (max-width: 1200px) {
    flex-direction: column;
    ul {
      padding: 2rem;
      justify-content: space-around;
      width: 100%;
    }

    li {
      padding: 1rem;
    }
  }

  @media (max-width: 750px) {
    a {
      font-size: 1.5rem;
    }
    ul {
      padding: 1rem;
    }

    li {
      padding: 0.5rem;
    }
  }

  @media (max-width: 600px) {
    ul {
      padding: 0.8rem;
    }
    li {
      padding: 0.3rem;
    }
  }
`;

const Line = styled(motion.div)`
  background: #D2D7D9;
  height: 0.1rem;
  width: 0%;
  left: 60%;
  @media (max-width: 1200px) {
    left: 40%;
  }
`;

export default Nav;