// Routes
import { Switch, Route, useLocation } from "react-router-dom";
// Components
import Nav from "./components/Nav";
// Styles
import GlobalStyle from "./components/GlobalStyles";
// Page Components
import Projects from "./pages/Projects";
import About from "./pages/About";
import Contact from "./pages/Contact"

function App() {
  const location = useLocation();
  window.onload = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100)
  }

  return (
    <div className="App">
      <GlobalStyle />
      <Nav />
      <Switch location={location} key={location.pathname}>
        <Route exact path="/">
          <About />
        </Route>
        <Route path="/projects">
          <Projects />
        </Route>
        <Route exact path="/contact" >
          <Contact />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
