export const fadeIn = {
    hidden: {
        opacity: 0
    },
    show: {
        opacity: 1,
        transition: {
            duration: 1,
            staggerChildren: 2
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.75
        }
    }
}

export const tagList = {
    hidden: {
        opacity: 0,
        height: 0
    },
    whileTap: {
        height: 50,
        opacity: 1,
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.3,
            duration: 0.4,
            ease: "easeIn"
        }
    },
    whileHover: {
        height: 50,
        opacity: 1,
        transition: {
            when: "beforeChildren",
            staggerChildren: 0.3,
            duration: 0.4,
            ease: "easeIn"
        },
    }
}

export const tagItem = {
    hidden: {
        opacity: 0,
        x: -50
    },
    whileHover: {
        opacity: 1, x: 0,
    },
    whileTap: {
        opacity: 1, x: 0,
    }

}

export const projectCard = {
    hidden: {
        opacity: 0,
        scale: 0.5
    },
    show: {
        opacity: 0.9,
        scale: 1,
        transition: {
            duration: 0.3
        }

    },
    whileTap: { scale: 1.1 },
    whileHover: { scale: 1.1 },
}

export const iconAnimation = {
    hidden: {
        opacity: 0,
        scale: 0.5
    },
    show: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.2
        }

    },
    whileTap: { scale: 1.1 },
    whileHover: { scale: 1.1 },
}