import React from "react";
import { Widget } from '@typeform/embed-react'
import styled from "styled-components";
import { motion } from "framer-motion";
import { fadeIn } from "../animations";

const Contact = () => {
    return (
        <StyledForm
            variants={fadeIn}
            initial="hidden"
            animate="show"
        >
            <Widget
                id="RnqL1Vqz"
                style={{ width: '100%', height: '100%' }}
                opacity="0"
            />
        </StyledForm>
    )
};

const StyledForm = styled(motion.div)`
    position: fixed;
    top:0;
    height: 100vh;
    width: 100%;
    z-index: -1;
`;


export default Contact;