import React, { useState } from "react";
import styled from "styled-components/macro";
import { motion } from "framer-motion";
import { fadeIn } from "../animations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faGithubSquare, faYoutubeSquare, faBlogger } from "@fortawesome/free-brands-svg-icons";
import { iconAnimation } from "../animations";
import { Link } from "react-router-dom";

const About = () => {
    const linkedIn = <a href="https://www.linkedin.com/in/veronica-hannibal/">
        <FontAwesomeIcon icon={faLinkedin} size="lg" inverse />
    </a>
    const github = <a href="https://github.com/Sumerian812">
        <FontAwesomeIcon icon={faGithubSquare} size="lg" inverse />
    </a>
    const youtube = <a href="https://www.youtube.com/channel/UCpOX_PFidL59WZbM2R2Oaig">
        <FontAwesomeIcon icon={faYoutubeSquare} size="lg" inverse />
    </a>
    const blogger = <a href="https://veronicascode.blogspot.com/">
        <FontAwesomeIcon icon={faBlogger} size="lg" inverse />
    </a>
    const [icons] = useState([linkedIn, github, youtube, blogger]);

    return (
        <StyledText
            variants={fadeIn}
            initial="hidden"
            animate="show"
        >
            <p>
                Hi there! I'm Veronica, a JavaScript developer based in Munich, Germany.
                <br />
                My programming journey began with Harvard's CS50 online course, where I discovered my passion for building applications with logic and code. Since then, I've pursued my interest further, earning a Bachelor's in Frontend Development from OpenClassrooms.
                <br />
                Currently, I specialize in developing web scraper interfaces for European banks, where I leverage my skills to create efficient and intuitive solutions.
                <br />
                Outside work, I love diving into new technologies and embarking on creative projects in my spare time. This portfolio showcases some of my recent endeavors.
                <br />
                If you'd like to connect, please reach out via the social links below or drop me a 
                <Link to="/contact" id="contactLink"> message</Link>.
            </p>
            <StyledList>
                {icons.map((icon, id) => (
                    <StyledListItem
                        variants={iconAnimation}
                        whileHover="whileHover"
                        whileTap="whileTap"
                        initial="hidden"
                        animate="show"
                        key={id}
                    >
                        {icon}
                    </StyledListItem>
                ))}
            </StyledList>
        </StyledText>
    )
};

const StyledText = styled(motion.div)`
    margin: 5% 10% 3% 5%;

    p { 
        font-size: 1.2rem;
        text-align: left;
        color: white;
        font-family: 'Playfair Display', serif;
        padding: 1rem;
    } 

    #contactLink {
        color: #63A69F;
    }

    @media (max-width: 600px) {
        margin: 2% 5% 3% 5%;
        font-size: 0.8rem;
  }
`;

const StyledList = styled(motion.ul)`
    padding-left: 1rem;
    display: flex;
    list-style: none;
`;

const StyledListItem = styled(motion.li)`
    padding-right: 1rem;
    position: relative;
`;
export default About;