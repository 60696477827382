import { createGlobalStyle } from "styled-components/macro";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    background: url("https://images.unsplash.com/photo-1626811407568-2f41c53fff99?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=748&q=80") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  body {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    max-width: 100%;
    overflow-x: hidden;
  }

  #form {
    width: 100%;
    height: 100%;
  }

  h2 {
    font-family: 'SAONARA', Arial, sans-serif;
  }

  h3 {
    font-family: 'SAONARA', Arial, sans-serif;
    font-weight: lighter;
    font-size: 1.3rem;
    color: black;
    padding: 1.5rem 0rem;
  }

  p {
    font-size: 1.2rem;
    line-height: 200%;
    color: #696969;
  }

  a {
    text-decoration: none;
    color: black;
  }

  img {
    display: block;
  }
`;

export default GlobalStyle;