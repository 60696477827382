import React, {useState} from "react";
import styled from "styled-components/macro";
import {motion} from "framer-motion";
// ProjectsState
import {projectsState} from "../projectsState";
// Components
import Project from "../components/Project";


const Projects = () => {
    const [projects] = useState(projectsState);
    return (
        <StyledProjects>
            {projects.map(project => (
                <Project
                    id={project.id}
                    title={project.title}
                    description={project.description}
                    tags={project.tags}
                    image={project.img}
                    url={window.screen.width < 1280 && project.altUrl ? project.altUrl : project.url}
                    key={project.id}
                />
            ))}
        </StyledProjects>
    );
}

const StyledProjects = styled(motion.div)`
  padding: 1rem 5rem 15rem 5rem;
  min-height: 80vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  grid-column-gap: 5%;
  grid-row-gap: 5%;
  @media screen and (min-width: 1600px) {
    padding: 1rem 5rem 5rem 5rem;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-column-gap: 3rem;
    grid-row-gap: 5rem;
  }
  @media screen and (max-width: 850px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    grid-row-gap: 2%;
    padding: 0.5rem 0.5rem 20rem 0.5rem;
  }
  @media screen and (max-width: 450px) {
    grid-template-columns: 99%;
    padding: 0.5rem 0.5rem 20rem 0.5rem;
  }
`;

export default Projects;