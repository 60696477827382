import React from 'react';
import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
// Route
import { Link } from "react-router-dom";
// Animations
import { projectCard, tagItem, tagList } from "../animations";


const Project = ({ id, title, description, tags, image, url }) => {
  return (
    <StyledProjectCard
      variants={projectCard}
      initial="hidden"
      animate="show"
      whileHover="whileHover"
      whileTap="whileTap"
    >
      <Link to={{ pathname: url }} target="_blank">
        <h2>{title}</h2>
        <StyledTagParent
          className="tags"
          variants={tagList}
        >
          {tags.map(tag => (
            <StyledTag
              key={tag}
              variants={tagItem}
            >
              {tag}
            </StyledTag>
          ))}
        </StyledTagParent>
        <p>{description}</p>
        <img src={image} alt={title} />
      </Link>
    </StyledProjectCard>
  );
}

const StyledProjectCard = styled(motion.div)`
  background-color: #D2D7D9;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border-radius: 0.5rem;
  overflow: hidden;
  height: 50vh;

  @media (min-width: 1400px) {
    height: 70vh;
  }

  img {
    width: 100%;
    height: 70%;
    object-fit: cover;
  }

  h2 {
    font-size: 2rem;
    color: #63A69F;
    text-transform: uppercase;
    padding: 1rem 1rem 0.5rem 1rem;
  }

  p {
    font-size: 0.9rem;
    color: #696969;
    padding: 0.5rem 1rem 0.5rem 1rem;
    line-height: 180%;
  }

  .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
`;

const StyledTagParent = styled(motion.div)`
`;

const StyledTag = styled(motion.span)`
  font-size: 0.7rem;
  background-color: #A3C4D9;
  padding: 0.3rem 1rem;
  margin: 0.1rem 0.1rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export default Project;